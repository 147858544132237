.link
  color: #fff
  font-size: 13px
  padding-bottom: 14px
  display: block
  text-decoration: none
  cursor: pointer

  &:hover
    text-decoration: underline

.placeholder
  font-size: 13px
  padding-bottom: 14px
  display: block
